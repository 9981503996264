import { Game } from '@/interfaces/game'
import { addDays, isBefore, isToday } from 'date-fns'

import { GAMES } from './../games/games'
import { firstGameDate } from './../lib/words'

const periodInDays = 1 // Update this with the actual period between games

const ArchiveUser = () => {
  const games = GAMES

  const today = new Date()

  // Filter the games up to today's date
  const filteredGames = games.filter((_, index) => {
    const gameDate = addDays(firstGameDate, index * periodInDays)
    return isBefore(gameDate, today) || isToday(gameDate)
  })

  // Reverse the filtered games
  const reversedGames = [...filteredGames].reverse()

  const handleGameClick = (game: Game) => {
    const gameData = {
      prompt: game.text,
      answer: game.answer,
      lucky: game.luckyGuess,
    }

    const encodedData = btoa(JSON.stringify(gameData))

    window.location.assign(`${window.location.origin}/?game=${encodedData}`)
  }

  return (
    <>
      <div className="flex items-center justify-center text-center text-black dark:text-white">
        <div>
          <h2 className="mb-2 mt-4 text-center text-xl font-bold">
            Game Archive
          </h2>
          {true ? (
            <>
              {reversedGames.map((game) => {
                // Calculate the game date based on its original index
                const originalIndex = GAMES.indexOf(game)
                // const gameDate = addDays(firstGameDate, originalIndex * periodInDays);
                // const formattedDate = format(gameDate, 'do MMM');
                return (
                  <p
                    key={originalIndex}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleGameClick(game)}
                    className="hover:underline"
                  >
                    <span style={{ cursor: 'pointer' }} className="">
                      {game.text}
                    </span>
                  </p>
                )
              })}
              <br />
              <br />
              <br />
            </>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default ArchiveUser
