import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

interface GameContextProps {
  isGameFromLink: boolean
  setGameFromLink: (value: boolean) => void
  checkQueryParams: () => void
  prompt: string
  answer: string
  lucky: string
}

// Create the context
export const GameContext = createContext<GameContextProps>({
  isGameFromLink: false,
  checkQueryParams: () => {},
  setGameFromLink: () => {},
  prompt: '',
  answer: '',
  lucky: '',
})

// Create the provider
export const GameProvider = ({ children }: { children: ReactNode }) => {
  const [isGameFromLink, setGameFromLink] = useState(false)
  const [prompt, setPrompt] = useState('')
  const [answer, setAnswer] = useState('')
  const [lucky, setLucky] = useState('')

  const checkQueryParams = () => {
    // Use URLSearchParams to work with query params
    const params = new URLSearchParams(window.location.search)

    if (params.has('game')) {
      const game = params.get('game')
      if (game) {
        try {
          const decodedString = atob(game)
          if (
            decodedString.includes('prompt') &&
            decodedString.includes('answer')
          ) {
            const obj = JSON.parse(decodedString)
            setPrompt(obj.prompt)
            setAnswer(obj.answer)
            setLucky(obj.lucky)
            setGameFromLink(true)
          }
        } catch (e) {
          setGameFromLink(false)
        }
      }
    } else {
      setGameFromLink(false)
    }
  }

  useEffect(() => {
    // Run the function once when the component is mounted
    checkQueryParams()
  }, [])

  return (
    <GameContext.Provider
      value={{
        isGameFromLink,
        setGameFromLink,
        checkQueryParams,
        prompt,
        answer,
        lucky,
      }}
    >
      {children}
    </GameContext.Provider>
  )
}

// Create a hook for easier use in functional components
export const useGameContext = () => useContext(GameContext)
