import { Game } from '@/interfaces/game'
import { addDays, format } from 'date-fns'
import { useState } from 'react'

import { GAMES } from './../games/games'
import { firstGameDate } from './../lib/words'

const periodInDays = 1 // Update this with the actual period between games

const Archive = () => {
  const [password, setPassword] = useState('')
  const [isPasswordCorrect, setPasswordCorrect] = useState(
    localStorage.getItem('passwordCorrect') === 'true'
  )
  const games = GAMES

  const handleGameClick = (game: Game) => {
    const gameData = {
      prompt: game.text,
      answer: game.answer,
      lucky: game.luckyGuess,
    }

    const encodedData = btoa(JSON.stringify(gameData))

    window.location.assign(`${window.location.origin}/?game=${encodedData}`)
  }

  const checkPassword = () => {
    if (password === 'solver') {
      setPasswordCorrect(true)
      localStorage.setItem('passwordCorrect', 'true')
    }
  }

  return (
    <>
      <div className="flex items-center justify-center text-center text-black dark:text-white">
        <div>
          <h2 className="mb-2 mt-4 text-center text-xl font-bold">
            Game Archive
          </h2>
          {isPasswordCorrect ? (
            <>
              <p className="mt-2 mb-2 text-lg">Hi Tom 👋</p>
              {games.map((game, index) => {
                const gameDate = addDays(firstGameDate, index * periodInDays)
                const formattedDate = format(gameDate, 'dd/MM/yyyy')
                return (
                  <p
                    key={index}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleGameClick(game)}
                    className="hover:underline"
                  >
                    {formattedDate}:{' '}
                    <span style={{ cursor: 'pointer' }} className="">
                      {game.text}
                    </span>
                  </p>
                )
              })}
              <br />
              <br />
              <br />
            </>
          ) : (
            <>
              <div className="flex flex-col">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter password"
                  className="mt-2 rounded border-2 border-gray-300 bg-white p-2 dark:border-neutral-700 dark:bg-slate-700"
                />
                <button
                  onClick={checkPassword}
                  className="mt-2 rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Archive
