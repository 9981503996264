import './index.css'

import React from 'react'
import { hydrate, render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App'
import { AlertProvider } from './context/AlertContext'
import { GameProvider } from './context/Custom'
import { UserProvider } from './context/UserProvider'

const rootElement = document.getElementById('root')
if (rootElement && rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <Router>
        <UserProvider>
          <GameProvider>
            <AlertProvider>
              <App />
            </AlertProvider>
          </GameProvider>
        </UserProvider>
      </Router>
    </React.StrictMode>,
    rootElement
  )
} else {
  render(
    <React.StrictMode>
      <Router>
        <UserProvider>
          <GameProvider>
            <AlertProvider>
              <App />
            </AlertProvider>
          </GameProvider>
        </UserProvider>
      </Router>
    </React.StrictMode>,
    rootElement
  )
}
