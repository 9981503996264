import dayjs from 'dayjs'

export function getDayString(shiftDayCount?: number) {
  let date = dayjs(Date.now())
  if (window) {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    if (params.forceDate !== undefined && params.forceDate.length === 10) {
      date = dayjs(params.forceDate)
    }
  }
  return date.add(shiftDayCount ?? 0, 'day').format('YYYY-MM-DD')
}
