import React, { useEffect, useState } from 'react'

import { useGameContext } from './../../context/Custom'
import { getGameDate, getIndex, getTextOfDay } from './../../lib/words'

type InputProps = {
  currentGuess: string
}

export const Input = ({ currentGuess }: InputProps) => {
  const gDate = getGameDate()
  const index = getIndex(gDate)
  const [gameText, setGameT] = useState(getTextOfDay(index))
  const { isGameFromLink, prompt } = useGameContext()

  useEffect(() => {
    if (isGameFromLink) {
      setGameT(prompt)
    } else {
      setGameT(getTextOfDay(index))
    }
  }, [isGameFromLink, prompt, index])

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <h2 className="mb-1 text-center text-base text-black dark:text-white md:text-lg">
          How does google{' '}
          <span className="product-sans-bold">autocomplete</span> this search?
        </h2>
        <div className="mx-3 md:mx-0">
          <div className="flex h-9  w-80 items-center rounded-full border-2 border-neutral-200 bg-white px-3 dark:bg-slate-600 dark:text-white md:h-11 md:w-96 md:text-lg">
            <img
              src="/icon_search.svg"
              alt="search"
              className="w-5 dark:invert"
            />
            <p className="ml-3 whitespace-nowrap">
              {isGameFromLink ? prompt : gameText}
              <span> {currentGuess.toLowerCase()}</span>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
