import { FC } from 'react'

import { Cell } from './Cell'

interface EmptyRowProps {
  solution: string
}

export const EmptyRow: FC<EmptyRowProps> = ({ solution }) => {
  const emptyCells = Array.from(Array(solution.length))

  return (
    <div className="mb-1 flex justify-center">
      {emptyCells.map((_, i) => (
        <Cell key={i} />
      ))}
    </div>
  )
}
