import { default as Filter } from 'bad-words'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

const CreateYourOwn = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
  } = useForm({
    mode: 'onChange',
  })

  const filter = new Filter()

  const [link, setLink] = useState('')
  const [copyButtonText, setCopyButtonText] = useState('Copy Link')
  const [textAreaValue, setTextAreaValue] = useState('')

  const onSubmit = (data: any) => {
    // Check for profanity
    if (filter.isProfane(data.prompt)) {
      setError('prompt', {
        type: 'manual',
        message: 'Prompt contains inappropriate language',
      })
      return
    }

    if (filter.isProfane(data.answer)) {
      setError('answer', {
        type: 'manual',
        message: 'Answer contains inappropriate language',
      })
      return
    }

    // Convert data to JSON and encode it with Base64
    const encodedData = btoa(JSON.stringify(data))
    // Create the URL with the encoded data
    const url = `${window.location.origin}/?game=${encodedData}`
    setLink(url)
    setTextAreaValue(url) // set textarea value

    // Reset the form after submit
    reset()
  }

  const copyToClipboard = () => {
    if (link) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          // Change button text
          setCopyButtonText('Copied!')

          // Revert back to the original button text after 1 second
          setTimeout(() => {
            setCopyButtonText('Copy Link')
          }, 1000)
        })
        .catch((err) => {
          console.error('Could not copy text: ', err)
        })
    }
  }

  const playGame = () => {
    if (link) {
      window.location.assign(link)
    }
  }

  return (
    <div className="mb-6 flex w-full flex-col items-center justify-center text-black dark:!text-white">
      <div className="mt-3">
        <h2 className="mb-2 text-center text-xl">Create your own</h2>
        <form onSubmit={handleSubmit(onSubmit)} className="flex w-72 flex-col">
          <label htmlFor="prompt" className="ml-0.5 text-base">
            Prompt
          </label>
          <input
            id="prompt"
            {...register('prompt', {
              required: 'Prompt is required',
              maxLength: {
                value: 30,
                message: "Prompt can't be longer than 30 characters",
              },
              pattern: {
                value: /^[A-Za-z\s]*$/,
                message: 'Prompt can only contain letters and spaces',
              },
            })}
            className="rounded-md border-2 border-gray-300 bg-white p-2 dark:bg-slate-700"
          />
          {errors.prompt && (
            <p className="text-red-500 dark:text-red-400">
              {errors.prompt.message}
            </p>
          )}

          <label htmlFor="answer" className="ml-0.5 mt-1 text-base">
            Answer
          </label>
          <input
            id="answer"
            {...register('answer', {
              required: 'Answer is required',
              maxLength: {
                value: 9,
                message: "Answer can't be longer than 9 characters",
              },
              pattern: {
                value: /^[A-Za-z]*$/,
                message: 'Answer can only contain letters',
              },
            })}
            className="rounded-md border-2 border-gray-300 bg-white p-2 dark:bg-slate-700"
          />
          {errors.answer && (
            <p className="text-red-500 dark:text-red-400">
              {errors.answer.message}
            </p>
          )}
          <button
            type="submit"
            className="mt-3 rounded-md bg-green-500 p-2 text-white"
          >
            Create
          </button>
        </form>
        {link && (
          <div className="mt-3 flex w-72 flex-col items-center justify-center overflow-hidden rounded-md">
            <p className="mb-1">Share this with your friends</p>
            <textarea
              value={textAreaValue}
              onChange={(e) => setTextAreaValue(e.target.value)}
              className="word-break: break-word h-28 w-full rounded-md text-center text-sm dark:bg-slate-700"
            ></textarea>
            <div className="flex gap-1">
              <button
                onClick={copyToClipboard}
                className="mt-3 rounded-md bg-blue-500 px-2 py-1 text-white"
              >
                <span>{copyButtonText}</span>
              </button>
              <button
                onClick={playGame}
                className="mt-3 rounded-md bg-[#F4b400] px-2 py-1 text-black"
              >
                <span>Play Game</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CreateYourOwn
