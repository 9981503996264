import { Helmet } from 'react-helmet'

export default function About() {
  ;<Helmet>
    <title>About</title>
    <meta
      name="description"
      content="About Searchle, your daily challenge where the thrill of guessing games meets the intrigue of Google search trends. As a spin-off of the viral game Wordle, Searchle invites you to guess the secret word of the day from a starting Google search prompt"
    />
  </Helmet>
  return (
    <>
      <div className="mb-10 flex w-full items-center justify-center px-6 md:px-0">
        <div className="mt-4 w-full max-w-md text-black dark:text-white">
          <h2 className="text-2xl font-bold">About</h2>
          <div className="mt-4 flex flex-col gap-3">
            <p>
              Welcome to Searchle, your daily challenge where the thrill of
              guessing games meets the intrigue of Google search trends. As a
              spin-off of the viral game{' '}
              <a
                href="https://www.nytimes.com/games/wordle/index.html"
                target="_blank"
                className="text-blue-600 dark:text-blue-300"
                rel="noreferrer"
              >
                Wordle
              </a>
              , Searchle invites you to guess the secret word of the day from a
              starting Google search prompt. From &apos;Why is my skin
              so...&apos; leading to &apos;pale&apos;, Searchle takes you on a
              fascinating journey into the popular online searches.
            </p>
            <p>
              Searchle is not just a game, it&apos;s a learning experience. By
              guessing the word, you tap into the collective curiosity of the
              internet, offering a peek into global consciousness. Join the
              thousands of daily players and be part of the Searchle community,
              a space for curious minds looking for a unique, thought-provoking
              challenge.
            </p>
            <hr></hr>
            <p>
              Searchle was made by{' '}
              <a
                href="https://ryancarmody.dev"
                target="_blank"
                className="text-blue-600 dark:text-blue-300"
                rel="noreferrer"
              >
                Ryan Carmody
              </a>{' '}
              and{' '}
              <a
                href="https://mplenterprises.com/our-team/"
                target="_blank"
                className="text-blue-600 dark:text-blue-300"
                rel="noreferrer"
              >
                Praveen Latchamsetty
              </a>
              .
            </p>
            <p>
              Managed by{' '}
              <a
                href="https://thewordfinder.com"
                target="_blank"
                className="text-blue-600 dark:text-blue-300"
                rel="noreferrer"
              >
                The Word Finder
              </a>
              , ads by{' '}
              <a
                href="https://snigel.com/"
                target="_blank"
                className="text-blue-600 dark:text-blue-300"
                rel="noreferrer"
              >
                Snigel
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
