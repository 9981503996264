import { isAxiosError } from 'axios';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';



import { UserDetails, initialUserDetailsState } from '../interfaces/UserDetails';
import { GameStats } from '../lib/localStorage';
import { defaultStats } from '../lib/stats';
import { getData, getUserDetails, refreshTokens, setData } from '../utils/account';
import { checkPhotoValidity, getDayString, isSameUtcDay } from '../utils/helpers';


/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
const UserContext = createContext({
  isLoaded: false,
  userDetails: initialUserDetailsState,
  logout: () => {},
  setUserDetails: (_userDetails: UserDetails) => {},
  statsUpdated: false,
  setStatsUpdated: (_statsUpdated: boolean) => {},
  playedTodayOnOtherDevice: false,
  setPlayedTodayOnOtherDevice: (_playedTodayOnOtherDevice: boolean) => {},
});

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetails>(
    initialUserDetailsState,
  );
  const [statsUpdated, setStatsUpdated] = useState(false);
  const [playedTodayOnOtherDevice, setPlayedTodayOnOtherDevice] =
    useState(false);

  const logout = () => {
    setUserDetails(initialUserDetailsState);
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('teuteuf-user');
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const syncData = async (initalSync: boolean = false) => {
      if (initalSync) {
        let stats = await getData<GameStats | null>('stats');
        stats ||= defaultStats;

        let shouldUploadStats = false

        const statsLocal = localStorage.getItem('gameStats')

        // Merge our stats in
        if (statsLocal) {
          try {
            const statsLocalParsed = JSON.parse(statsLocal)
            if (statsLocalParsed.totalGames > stats.totalGames) {
              stats = { ...statsLocalParsed }
              shouldUploadStats = true
            }
          } catch (e) {
            console.error('Error parsing stats', e)
          }
        }

        localStorage.setItem('gameStats', JSON.stringify(stats));
        const promises = [];
        if (shouldUploadStats) {
          promises.push(setData('stats', stats));
        }
        await Promise.all(promises);
      }
    };

    async function loadUserAndRefreshTokens() {
      const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
      const encodedRefreshTime = localStorage.getItem('refreshDate');
      const refreshTime = encodedRefreshTime
        ? decodeURIComponent(encodedRefreshTime)
        : null;

      // Load user from local storage - This could be stale data, but will update after refresh.
      const userFromLocalStorage = localStorage.getItem('teuteuf-user');

      try {
        // Read hash, and try and log the user in
        const hash = window.location.hash;
        if (hash.startsWith('#userData=')) {
          const rawUserData = decodeURIComponent(
            hash.substring('#userData='.length),
          );
          const result = JSON.parse(rawUserData);
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('token', result.token);
          localStorage.setItem('refreshToken', result.refreshToken);
          localStorage.setItem('refreshDate', new Date().toUTCString());
          // Once logged in, redirect to homepage
          window.location.href = '/';
        }
      } catch (e) {
        console.error('Error logging in', e);
      }

      if (isLoggedIn) {
        if (userFromLocalStorage) {
          const parsedUser: UserDetails = JSON.parse(userFromLocalStorage)
          setUserDetails(parsedUser)
        }
        try {
          if (refreshTime && !isSameUtcDay(refreshTime)) {
            console.log('Refreshing tokens...')
            await refreshTokens()
          } else {
            console.log('Not refreshing tokens')
          }

          const response = await getUserDetails()
          if (response) {
            const newUserDetails: UserDetails = {
              loggedIn: true,
              firstName: response.data.firstName,
              photoURL: response.data.photoURL,
              isPhotoValid: false, // default to false until we verify the photo
              marketingOptIn: String(response.data.marketingOptIn),
              subscription: response.data.subscription,
              premiumGames: response.data.premiumGames ?? [],
            }
            setUserDetails(newUserDetails)
            localStorage.setItem('teuteuf-user', JSON.stringify(newUserDetails))

            checkPhotoValidity(newUserDetails.photoURL).then((isValid) => {
              setUserDetails((prevState) => {
                const updatedUserDetails = {
                  ...prevState,
                  isPhotoValid: isValid,
                }
                localStorage.setItem(
                  'teuteuf-user',
                  JSON.stringify(updatedUserDetails)
                )
                return updatedUserDetails
              })
            })

            await syncData(true)
          } else {
            // Not logged in!
            setUserDetails(initialUserDetailsState)
          }
        } catch (e) {
          // check if network error
          if (isAxiosError(e) && e.response?.status) {
            setUserDetails(initialUserDetailsState)
            localStorage.removeItem('datePlayedOnOtherDevice')
            localStorage.setItem(
              'teuteuf-user',
              JSON.stringify(initialUserDetailsState)
            )
          }
          console.log(e)
        } finally {
          setIsLoaded(true)
        }
      } else {
        setIsLoaded(true)
      }
    }
    loadUserAndRefreshTokens();
    const datePlayedOnOtherDevice = localStorage.getItem(
      'datePlayedOnOtherDevice',
    );
    if (datePlayedOnOtherDevice && datePlayedOnOtherDevice === getDayString()) {
      console.log('setting played today on other device');
      setPlayedTodayOnOtherDevice(true);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        logout,
        isLoaded,
        userDetails,
        setUserDetails,
        statsUpdated,
        setStatsUpdated,
        playedTodayOnOtherDevice,
        setPlayedTodayOnOtherDevice,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;