export default function Feedback() {
  return (
    <>
      <div className="mb-10 flex w-full items-center justify-center px-6 md:px-0">
        <div className="mt-4 w-full max-w-md text-black dark:text-white">
          <h2 className="text-2xl font-bold">Feedback</h2>
          <div className="mt-4 flex flex-col gap-3">
            <p>
              Do you have feedback for Searchle? We're a small team that works
              on this game and want to make it as enjoyable as possible.
            </p>
            <p>
              If you'd like to help imporve the game, follow the link below
              which will take you to a google form.
            </p>
            <a
              href="https://teuteuf.notion.site/17eeff4e9f3880e2a6aeef075e74939b"
              target="_blank"
              rel="noreferrer"
            >
              <button className="mt-3 rounded-md bg-green-500 p-2 text-white">
                Submit feedback
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
