export default function Privacy() {
  return (
    <>
      <div className="mb-10 flex w-full items-center justify-center px-6 md:px-0">
        <div className="mt-4 w-full max-w-md text-black dark:text-white">
          <h2 className="text-2xl font-bold">Privacy policy</h2>
          <div className="mt-4 flex flex-col gap-3">
            <p>
              Your privacy is important to us. To better protect your sensitive
              information, we provide this notice explaining our online
              information practices and how they relate specifically in regard
              of what type or quantity data might be collected from you by way
              of using the website at any given time during browsing sessions on
              either desktop computers, tablets, or mobile devices.
            </p>
            <h3 className="font-bold">Our Commitment</h3>
            <p>
              We do not collect any personal information on this site. Third
              party vendors may use cookies to serve ads based on a user’s prior
              visits to your website or other websites. Google’s use of
              advertising cookies enables it and its partners to serve ads to
              your users based on their visit to your sites and/or other sites
              on the Internet. Users may opt out of personalized advertising.
            </p>
            <h3 className="font-bold">Information We Collect</h3>
            <p>
              We collect the IP address and click patterns of our users for a
              period, but this data is never sold or shared with anyone else. We
              use it to see how people interact online so that we can make
              improvements on what they experience in their user interface (UI).
              Our email addresses are kept only as long as necessary; when
              responding back from customer inquiries or feedback–we do not
              reuse those same respondents’ names again next time around!
            </p>
            <h3 className="font-bold">Third Party Services</h3>
            <p>
              We collect generic website analytics by way of website browser and
              cookies. Our analytics data collected is anonymous. We may work
              with third-party advertising companies to deliver ads when you
              visit our site. These firms may use aggregated information about
              your internet activity and other websites that are visited in
              order provide advertisements relevant for you on this platform
              only, but not including any personal details such as name or
              address.
            </p>
            <p>
              We use a third-party to provide monetization technologies for our
              site. You can review their privacy and cookie policy here.
            </p>
            <h4>California Consumer Privacy Act (CCPA)</h4>
            <p>Manage your consent by way of the CCPA</p>
            <h3 className="font-bold">Log Data</h3>
            <p>
              We collect certain log data whenever you access our website or
              services. The information we collect may include your IP address,
              device name and version as well as the time you utilize our
              service. We use these and other statistics to help us provide
              improvements to the website.
            </p>
            <h3 className="font-bold">Cookies</h3>
            <p>
              Cookies are files with a small amount of data that are commonly
              used as anonymous unique identifiers. These are sent to your
              browser from the websites that you visit and are stored on your
              device’s internal memory.
            </p>
            <p>
              This Service does not use these “cookies” explicitly. However, the
              app/website may use third party code and libraries that use
              “cookies” to collect information and improve their services. You
              have the option to either accept or refuse these cookies and know
              when a cookie is being sent to your device. If you choose to
              refuse our cookies, you may not be able to use some portions of
              this Service.
            </p>
            <h3 className="font-bold">Contact</h3>
            <p>
              We would love to hear from you! Whether you have a concern or
              simply want to say hello, please get in touch with us.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
