import { Helmet } from 'react-helmet'

import { useUser } from '../context/UserProvider'

export const AccountRoute = () => {
  const { userDetails, logout } = useUser()

  const authUrl =
    window?.location.hostname === 'localhost'
      ? 'http://localhost:3000/auth'
      : 'https://auth.teuteuf.fr/auth'

  const referer =
    window?.location.hostname === 'localhost' ? 'localhost-3001' : 'searchle'

  const socialLogins = [
    {
      name: 'X',
      path: 'twitter',
      icon: '/images/icons/X.svg',
      className: 'bg-black text-white',
    },
    {
      name: 'Google',
      path: 'google',
      icon: '/images/icons/google.png',
      className: 'bg-[#4285f4] text-white',
    },
    {
      name: 'Apple',
      path: 'apple',
      icon: '/images/icons/apple.png',
      className: 'bg-black text-white',
    },
  ]

  return (
    <>
      <Helmet>
        <title>Account</title>
        <meta
          name="Searchle - Account"
          content="About Searchle, your daily challenge where the thrill of guessing games meets the intrigue of Google search trends. As a spin-off of the viral game Wordle, Searchle invites you to guess the secret word of the day from a starting Google search prompt"
        />
      </Helmet>
      <div className="mb-10 flex w-full items-center justify-center px-6 md:px-0">
        <div className="mt-4 w-full max-w-md text-black dark:text-white">
          <h3 className="mb-3 text-xl font-bold">Account</h3>
          {!userDetails.loggedIn && (
            <div>
              <p className="mt-3">
                Sign up for a Teuteuf Games account or log in to start saving
                your gaming statistics and playing across multiple devices. Want
                to know all the benefits?
                <a
                  href="https://account.teuteuf.fr"
                  className="cursor-pointer text-blue-500 hover:text-blue-400 dark:text-blue-400"
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  Discover more here
                </a>
              </p>
              <div className="mt-4 flex gap-2">
                <div className="flex flex-col flex-wrap items-center gap-2 sm:flex-row">
                  {socialLogins.map((socialLogin) => (
                    <a
                      className={`block p-1 ${socialLogin.className} flex w-52 cursor-pointer items-center rounded-md`}
                      href={`${authUrl}/${socialLogin.path}?referer=${referer}`}
                      key={socialLogin.path}
                    >
                      <div className="flex h-10 w-10 items-center justify-center rounded bg-white p-1.5">
                        <img src={socialLogin.icon} alt={socialLogin.name} />
                      </div>{' '}
                      <div className="flex-1 text-center text-sm">
                        {`Login with ${socialLogin.name}`}
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          )}
          {userDetails.loggedIn && (
            <div>
              <p className="mt-3">Hi {userDetails.firstName} 👋</p>
              <p className="mt-2">
                We now store your stats after each game. This not only
                safeguards your progress but also enables seamless gameplay
                across multiple devices.
              </p>
              <p>
                <a
                  href="https://account.teuteuf.fr"
                  className="cursor-pointer text-blue-500 hover:text-blue-400 dark:text-blue-400"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more
                </a>
              </p>
              <div className="mt-4 flex gap-2">
                <button
                  onClick={logout}
                  className="rounded bg-blue-600 py-2 px-4 font-bold text-white hover:bg-blue-400"
                >
                  Sign out
                </button>
                <a href="https://account.teuteuf.fr" target='_blank' rel="noreferrer">
                  <button className="rounded border border-slate-400 py-2 px-4 font-bold text-black dark:text-white">
                    Manage account
                  </button>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default AccountRoute
